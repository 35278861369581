/* Balford */
@font-face {
    font-family: 'Balford_extras';
    src: url('assets/fonts/Balford/Balford_extras-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Balford Shadow';
    src: url('assets/fonts/Balford/Balford-Shadow.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Balford Base';
    src: url('assets/fonts/Balford/Balford-Base.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* Figuera */
@font-face {
    font-family: 'Figuera Variable Bold Condesed';
    src: url('assets/fonts/Figuera/FigueraVariable-BoldCondesed.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figuera Variable Extended';
    src: url('assets/fonts/Figuera/FigueraVariable-RegularExtended.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figuera Variable Light Sm Extd';
    src: url('assets/fonts/Figuera/FigueraVariable-LightSemiExtended.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figuera Variable';
    src: url('assets/fonts/Figuera/FigueraVariable-BoldSemiExtended.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figuera Variable';
    src: url('assets/fonts/Figuera/FigueraVariable-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figuera Variable Light Extended';
    src: url('assets/fonts/Figuera/FigueraVariable-LightExtended.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figuera Variable Condesed';
    src: url('assets/fonts/Figuera/FigueraVariable-Condesed.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figuera Variable Bd Sm Condesed';
    src: url('assets/fonts/Figuera/FigueraVariable-BoldSemiCondesed.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figuera Variable Semi Condesed';
    src: url('assets/fonts/Figuera/FigueraVariable-SemiCondesed.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figuera Variable';
    src: url('assets/fonts/Figuera/FigueraVariable-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figuera Variable Light Sm Cond';
    src: url('assets/fonts/Figuera/FigueraVariable-LightSemiCondensed.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figuera Variable';
    src: url('assets/fonts/Figuera/FigueraVariable-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figuera Variable Light Cond';
    src: url('assets/fonts/Figuera/FigueraVariable-LightCondensed.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figuera Variable Semi Expanded';
    src: url('assets/fonts/Figuera/FigueraVariable-RegularSemiExpanded.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figuera Variable Semi Expanded';
    src: url('assets/fonts/Figuera/FigueraVariable-RegularSemiExpanded.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figuera Variable Bold Extended';
    src: url('assets/fonts/Figuera/FigueraVariable-BoldExtended.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

/* LaBelleAurore */
@font-face {
    font-family: 'La Belle Aurore';
    src: url('assets/fonts/LaBelleAurore/LaBelleAurore.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* Schneidler */
@font-face {
    font-family: 'Cloister URW';
    src: url('assets/fonts/Schneidler/CloisterURW-TruCon.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Schneidler BT';
    src: url('assets/fonts/Schneidler/SchneidlerBT-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Schneidler Blk BT';
    src: url('assets/fonts/Schneidler/SchneidlerBT-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Schneidler BT';
    src: url('assets/fonts/Schneidler/SchneidlerBT-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Schneidler Lt BT';
    src: url('assets/fonts/Schneidler/SchneidlerBT-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Schneidler Blk BT';
    src: url('assets/fonts/Schneidler/SchneidlerBT-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Schneidler Md BT';
    src: url('assets/fonts/Schneidler/SchneidlerBT-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Schneidler BT';
    src: url('assets/fonts/Schneidler/SchneidlerBT-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Schneidler BT';
    src: url('assets/fonts/Schneidler/SchneidlerBT-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Schneidler Md BT';
    src: url('assets/fonts/Schneidler/SchneidlerBT-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Schneidler Lt BT';
    src: url('assets/fonts/Schneidler/SchneidlerBT-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
